<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <v-row  >
           <v-col md="3" cols="12">
            <v-card
                link
                to="/"
                class="mx-auto"
                max-width="100%"
                outlined
                color="rgb(44, 148, 18)"
                elevation="1"
            >
                <v-list-item three-line>
                <v-list-item-content>
                    <div style="color:white" class="text-overline mb-4">
                       <strong>Projects</strong>
                    </div>
                    <v-list-item-title style="color:white;font-weight:bold" class="text-h5 mb-1">
                        <v-icon color="white">mdi-chart-bar</v-icon>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar
                      tile
                      size="120"
                      color="white"
                  ><v-img max-width="100%" src="@/assets/project.png"></v-img>
                  </v-list-item-avatar>
                </v-list-item>


                </v-card>
            </v-col>

              <v-col md="3" cols="12">
                  <v-card
                      link
                      to="/"
                      class="mx-auto"
                      max-width="100%"
                      outlined
                      color="rgb(44, 148, 18)"
                      elevation="1"
                  >
                      <v-list-item three-line>
                      <v-list-item-content>
                          <div style="color:white" class="text-overline mb-4">
                            <strong>Completed</strong> 
                          </div>
                          <v-list-item-title style="color:white;font-weight:bold" class="text-h5 mb-1">
                              <v-icon color="white">mdi-check</v-icon>
                          </v-list-item-title>
                      </v-list-item-content>
                          <v-list-item-avatar
                            tile
                            size="120"
                            color="white"
                        ><v-img max-width="100%" src="@/assets/completed.png"></v-img>
                        </v-list-item-avatar>
                      </v-list-item>


                  </v-card>
              </v-col>

          <v-col md="3" cols="12">
                  <v-card
                      link
                      to="/"
                      class="mx-auto"
                      max-width="100%"
                      outlined
                      color="rgb(44, 148, 18)"
                      elevation="1"
                  >
                      <v-list-item three-line>
                      <v-list-item-content>
                          <div style="color:white" class="text-overline mb-4">
                            <strong>On going</strong> 
                          </div>
                          <v-list-item-title style="color:white;font-weight:bold" class="text-h5 mb-1">
                              <v-icon color="white">mdi-clover</v-icon>
                          </v-list-item-title>
                      </v-list-item-content>
                        <v-list-item-avatar
                            tile
                            size="120"
                            color="white"
                            rounded
                        ><v-img max-width="100%" src="@/assets/ongoing.png"></v-img>
                        </v-list-item-avatar>
                      </v-list-item>


                  </v-card>
              </v-col>

              <v-col md="3" cols="12">
                  <v-card
                      link
                      to="/"
                      class="mx-auto"
                      max-width="100%"
                      outlined
                      color="rgb(44, 148, 18)"
                      elevation="1"
                  >
                      <v-list-item three-line>
                      <v-list-item-content>
                          <div style="color:white" class="text-overline text-h5 mb-4">
                              <strong>Terminated</strong> 
                          </div>
                          <v-list-item-title style="color:white;font-weight:bold" class="text-h5 mb-1">
                              <v-icon color="white">mdi-book-alphabet</v-icon>
                          </v-list-item-title>
                          
                      </v-list-item-content>
                        <v-list-item-avatar
                            tile
                            size="120"
                            color="white"
                            rounded
                        ><v-img max-width="100%" src="@/assets/cancel.png"></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                  </v-card>
              </v-col>
        </v-row>
      
        <v-row>
      <v-col sm="6" class="mt-4" >
          <v-card rounded="4">
            <projectTrendBarCharts />
          </v-card>
         
       </v-col>
       <v-col sm="6" class="mt-4" >
          <v-card rounded="4">
            <projectTrendBarCharts />
          </v-card>
         
       </v-col>
        <div class="col-sm-6 col-md-4 col-12">
          <div class="v-card_title align-start">
            <span>Weekely Overview</span>
            <div class="spacer"></div>
            <button type="button" class="mt-n2 me-n3 v-btn v-btn--icon v-btn--round them--light v-size--default primary--text">
              <span class="v-btn_content">
                <span aria-hidden="true" class="v-icon notransalate theme--light" style="font-size: 22px; height: 22px; width:22px;" >
                  <v-icon>
                    mdi-dots
                  </v-icon>
              <div class="v-card__text" 
              style="position: relative;">
              <div style="min-height: 225px;">
                <div id="apexchartstey9h2iw"
                class="apexcharts-canvas apexchartstey9h2iw apexcharts-theme-light"
                  style="width: 341px; height: 210px;">
                </div>

              </div>
              </div>
                  
                </span>
              </span>
            </button>

          </div>

        </div>

        </v-row>
        
    </v-container>
  </sequential-entrance>

</template>

<script>
import projectTrendBarCharts from "../../digitaltp_charts/projectTrendBarCharts.vue"

export default {
  name: "DashBoard",

    components: {
      projectTrendBarCharts
    },

    data: () => ({
        hovered:true,
      overlay: false,

    }),
};
</script>




